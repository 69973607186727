import React from 'react';

import { styled } from '@mui/material/styles';

import { useQueryProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolInstanceProvider } from 'client/app/apps/protocols/context/ProtocolInstanceProvider';
import { SimulationProvider } from 'client/app/apps/protocols/context/SimulationProvider';
import { StepsProvider } from 'client/app/apps/protocols/context/StepsProvider';
import { WorkflowProvider } from 'client/app/apps/protocols/context/WorkflowProvider';
import { EditProtocolInstance } from 'client/app/apps/protocols/EditProtocolInstance';
import UIErrorBox from 'client/app/components/UIErrorBox';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  id: ProtocolInstanceId;
};

export default function EditProtocolInstanceScreen({ id }: Props) {
  const { data, loading, error } = useQueryProtocolInstance(id);

  if (error) return <UIErrorBox>Could not load protocol instance</UIErrorBox>;
  if (loading || !data) return <LinearProgress />;

  const { instance, elementContext } = data.protocolInstance;
  const { protocol, workflow } = instance.protocol;
  const { steps } = protocol;

  return (
    <Wrapper>
      <WorkflowProvider workflow={workflow} elementContext={elementContext}>
        <ProtocolInstanceProvider instance={instance}>
          <StepsProvider steps={steps}>
            <SimulationProvider>
              <EditProtocolInstance />
            </SimulationProvider>
          </StepsProvider>
        </ProtocolInstanceProvider>
      </WorkflowProvider>
    </Wrapper>
  );
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
}));
