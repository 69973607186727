import { useState } from 'react';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { ProtocolStep } from 'common/types/Protocol';

export const useProtocolStep = () => {
  const [tabId, setTabId] = useState(ElementDetailsTabs.INPUTS);
  const { changeStep, deleteStep, steps } = useProtocolContext();
  const [activeStepId, setActiveStepId] = useState(steps[0]?.id);

  const getStepProps = (step: ProtocolStep) => {
    const active = step.id === activeStepId;
    const canDelete = steps.length > 1;
    const onDelete = () => {
      deleteStep(step.id);
      if (activeStepId === step.id) {
        const newSteps = steps.filter(items => items.id !== step.id);

        setActiveStepId(newSteps[0].id);
      }
    };

    return {
      step,
      active,
      canDelete,
      onDelete,
      onChange: changeStep,
      onActivate: () => setActiveStepId(step.id),
    };
  };

  return {
    tabId,
    setTabId,
    activeStep: steps.find(step => step.id === activeStepId),
    getStepProps,
  };
};
