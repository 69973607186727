import { useMutation, useQuery } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  CREATE_PROTOCOL,
  CREATE_PROTOCOL_INSTANCE,
  UPDATE_PROTOCOL,
  UPDATE_PROTOCOL_INSTANCE,
  UPDATE_PROTOCOL_WORKFLOW,
} from 'client/app/api/gql/mutations';
import { QUERY_PROTOCOL, QUERY_PROTOCOL_INSTANCE } from 'client/app/api/gql/queries';
import {
  CreateProtocolInstanceMutation,
  CreateProtocolMutation,
  ProtocolQuery,
} from 'client/app/gql';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import { ParameterMap } from 'common/types/schema';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

/**
 * Queries a protocol instance.
 *
 * @returns Protocol instance and loading state.
 */
export function useQueryProtocolInstance(protocolInstanceId: ProtocolInstanceId) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL_INSTANCE, {
    variables: { id: protocolInstanceId },
  });
  return { data, loading, error };
}

/**
 * Queries a protocol
 *
 * @returns Protocol and loading state.
 */
export function useQueryProtocol(protocolId: ProtocolId, version: ProtocolVersion) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL, {
    variables: { id: protocolId, version },
  });
  return { data, loading, error };
}

/**
 * Creates a new protocol from an existing workflow.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocol() {
  const [createProtocol, { loading }] = useMutation(CREATE_PROTOCOL);

  const handleCreateProtocol = async (
    name: string,
    workflowId: WorkflowId,
  ): Promise<CreateProtocolMutation['createProtocol']> => {
    const updateResult = await createProtocol({
      variables: {
        input: {
          name,
          workflowId,
        },
      },
    });

    return getResultOrThrow(updateResult, 'Create protocol', data => data.createProtocol);
  };

  return { handleCreateProtocol, loading };
}
/**
 * Creates a new protocol instance from an existing protocol.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstance() {
  const [createProtocolInstance, { loading }] = useMutation(CREATE_PROTOCOL_INSTANCE);
  const handleCreateProtocolInstance = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ): Promise<CreateProtocolInstanceMutation['createProtocolInstance']> => {
    const updateResult = await createProtocolInstance({
      variables: {
        input: {
          protocolId: protocolId,
          protocolVersion: protocolVersion,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Create protocol instance',
      data => data.createProtocolInstance,
    );
  };

  return { handleCreateProtocolInstance, loading };
}

/**
 * Creates a new protocol from an existing workflow and navigates
 * to the editing route for that new protocol.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocolAndNavigate() {
  const { handleCreateProtocol, loading } = useCreateProtocol();
  const { navigate } = useNavigation();
  const handleCreateProtocolAndNavigate = async (
    name: string,
    workflowId: WorkflowId,
  ) => {
    const updateResult = await handleCreateProtocol(name, workflowId);
    if (updateResult?.protocol) {
      navigate(protocolsRoutes.editProtocol, {
        id: updateResult.protocol.id,
        version: '1',
      });
    }
  };

  return { handleCreateProtocolAndNavigate, loading };
}

/**
 * Creates a new protocol instance from an existing protocol and navigates
 * to the editing route for that new protocol instance.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstanceAndNavigate() {
  const { handleCreateProtocolInstance, loading } = useCreateProtocolInstance();
  const { navigate } = useNavigation();
  const handleCreateProtocolInstanceAndNavigate = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ) => {
    const updateResult = await handleCreateProtocolInstance(protocolId, protocolVersion);
    if (updateResult?.protocolInstance) {
      navigate(protocolsRoutes.editProtocolInstance, {
        id: updateResult.protocolInstance.id,
      });
    }
  };

  return { handleCreateProtocolInstanceAndNavigate, loading };
}

export type ProtocolUpdate = {
  protocol?: NonNullable<ProtocolQuery['protocol']['protocol']>;
  name?: string;
  shortDescription?: string;
};

/**
 * Updates the protocol
 *
 * @returns Handler for updating protocol and loading state.
 */
export function useUpdateProtocol(id: ProtocolId, version: ProtocolVersion) {
  const [updateProtocol, { loading }] = useMutation(UPDATE_PROTOCOL);

  const handleUpdateProtocol = async (editVersion: number, values: ProtocolUpdate) => {
    await updateProtocol({
      variables: {
        input: {
          id,
          version,
          editVersion,
          ...values,
        },
      },
    });
  };
  return { handleUpdateProtocol, loading };
}

/**
 * Updates the workflow
 *
 * @returns Handler for updating workflow and loading state.
 */
export function useUpdateWorkflow() {
  const [updateProtocolWorkflow, { loading }] = useMutation(UPDATE_PROTOCOL_WORKFLOW);

  const handleUpdateWorkflow = async (
    id: WorkflowId,
    version: number,
    workflow: WorkflowBlob,
  ) => {
    await updateProtocolWorkflow({
      variables: {
        input: {
          id,
          version,
          workflow,
        },
      },
    });
  };
  return { handleUpdateWorkflow, loading };
}

type ProtocolInstanceUpdate = {
  name?: string;
  params?: ParameterMap;
};

/**
 * Updates the protocol instance with parameters or name
 *
 * @returns Handler for updating protocol instance and loading state.
 */
export function useUpdateProtocolInstance(id: ProtocolInstanceId) {
  const [updateProtocolInstance, { loading }] = useMutation(UPDATE_PROTOCOL_INSTANCE);

  const handleUpdateProtocolInstance = async (
    editVersion: number,
    opts: ProtocolInstanceUpdate = {},
  ) => {
    const { name, params } = opts;
    const updateResult = await updateProtocolInstance({
      variables: {
        input: {
          id,
          name,
          editVersion,
          params,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Update protocol instance',
      data => data.updateProtocolInstance,
    );
  };

  return { handleUpdateProtocolInstance, loading };
}
