import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import partial from 'lodash/partial';
import { v4 as uuid } from 'uuid';

import { useFetchGraphQLDefaultElementSet } from 'client/app/api/ElementsApi';
import {
  ProtocolUpdate,
  useQueryProtocol,
  useUpdateProtocol,
  useUpdateWorkflow,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { deserialiseElements } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { ProtocolQuery } from 'client/app/gql';
import { Markdown } from 'common/lib/markdown';
import { ElementInstance, Parameter } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import { newElementPath, Schema } from 'common/types/schema';

type ProtocolContextType = {
  workflowId: WorkflowId | undefined;
  protocol: NonNullable<ProtocolQuery['protocol']['protocol']>;
  schema?: Schema;
  steps: ProtocolStep[];
  addNewStep: () => void;
  toggleStepInputs: (
    activeStep: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, checked: boolean) => void;
  deleteStepInput: (activeStep: ProtocolStep) => (index: number) => void;
  toggleStepOutputs: (
    activeStep: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, checked: boolean) => void;
  deleteStepOutput: (activeStep: ProtocolStep) => (index: number) => void;
  changeStep: (step: ProtocolStep) => void;
  deleteStep: (stepId: string) => void;
  getElementInstance: (elementInstanceID: string) => ElementInstance | undefined;
  name: string;
  shortDescription: string;
  update: (values: ProtocolUpdate) => void;
  exampleSimulation: ProtocolQuery['protocol']['exampleSimulation'];
};

export const ProtocolContext = createContext<ProtocolContextType | undefined>(undefined);

type ProtocolProviderProps = {
  protocolId: ProtocolId;
  version: ProtocolVersion;
};

export const useProtocolContext = () => {
  const context = useContext(ProtocolContext);

  if (context === undefined) {
    throw new Error('useProtocolContext must be used within a ProtocolProvider');
  }

  return context;
};

export const ProtocolProvider: FC<ProtocolProviderProps> = ({
  protocolId,
  version,
  children,
}) => {
  const fetchGraphQLDefaultElementSet = useFetchGraphQLDefaultElementSet();
  const [deserialisedElementInstances, setDeserialisedElementInstances] = useState<
    ElementInstance[]
  >([]);
  const {
    data,
    loading,
    // TODO: Will implement error handling in follow up PRs
    // error
  } = useQueryProtocol(protocolId, version);

  const workflowId = useMemo(
    () => data?.protocol.workflow.id,
    [data?.protocol.workflow.id],
  );
  const elementInstances = useMemo(
    () => data?.protocol.workflow.workflow.Elements.Instances,
    [data?.protocol.workflow.workflow.Elements.Instances],
  );

  useEffect(() => {
    const fetchElementSet = async () => {
      if (elementInstances) {
        const elementSet = await fetchGraphQLDefaultElementSet();
        const deserialisedElements = deserialiseElements(
          elementInstances,
          elementSet.elements,
        );
        setDeserialisedElementInstances(deserialisedElements.elementInstances);
      }
    };
    void fetchElementSet();
  }, [elementInstances, fetchGraphQLDefaultElementSet]);

  const { handleUpdateProtocol } = useUpdateProtocol(protocolId, version);
  const { handleUpdateWorkflow } = useUpdateWorkflow();

  if (loading || !data) return <LinearProgress />;

  const workflow = data.protocol.workflow.workflow;
  const workflowVersion = data.protocol.workflow.version;
  const protocol = data.protocol.protocol;
  const editVersion = data.protocol.editVersion;
  const schema = data.protocol.workflow.workflow.Schema;
  const name = data.protocol.name;
  const shortDescription = data.protocol.shortDescription;
  const steps = protocol.steps;
  const exampleSimulation = data.protocol.exampleSimulation;

  const addNewStep = async () => {
    const steps = protocol.steps;
    const stepsCount = steps.length;
    const newStep = {
      id: uuid(),
      displayName: `New Step ${stepsCount + 1}`,
      inputs: [],
      outputs: [],
    };
    await handleUpdateProtocol(editVersion, {
      protocol: { ...protocol, steps: [...steps, newStep] },
    });
  };

  const toggleStepInputs =
    (activeStep: ProtocolStep, elementInstanceId: string) =>
    async (param: Parameter, checked: boolean) => {
      const stepIndex = steps.findIndex(step => step.id === activeStep.id);
      const id = uuid();
      const displayName = param.configuration?.displayName || param.name;
      const updatedInputs = checked
        ? [
            ...activeStep.inputs,
            {
              id,
              displayName,
              elementInstanceId,
              displayDescription: param.configuration?.displayDescription as Markdown,
              configuration: param.configuration?.editor,
            },
          ]
        : activeStep.inputs.filter(input => input.displayName !== displayName);
      const newStep = { ...activeStep, inputs: updatedInputs };
      const newSteps = [
        ...steps.slice(0, stepIndex),
        newStep,
        ...steps.slice(stepIndex + 1),
      ];
      await handleUpdateProtocol(editVersion, {
        protocol: { ...protocol, steps: newSteps },
      });

      if (checked) {
        const instances = Object.values(workflow.Elements.Instances);
        const instance = instances.find(ele => ele.Id === elementInstanceId);
        if (instance && workflowId) {
          const defaults = instance.Parameters[param.name];
          const newSchemaInput = {
            id,
            path: newElementPath(elementInstanceId, param.name),
            default: defaults,
            typeName: param.type,
          };
          const updatedSchemaInputs = [...(schema?.inputs || []), newSchemaInput];
          const updatedSchema = { ...workflow.Schema, inputs: updatedSchemaInputs };
          await handleUpdateWorkflow(workflowId, workflowVersion, {
            ...workflow,
            Schema: updatedSchema,
          });
        }
      } else {
        if (workflowId) {
          const updatedSchemaInputs = schema?.inputs?.filter(input => {
            const path = input.path;
            return !(path.includes(param.name) && path.includes(elementInstanceId));
          });
          const updatedSchema = { ...workflow.Schema, inputs: updatedSchemaInputs };
          await handleUpdateWorkflow(workflowId, workflowVersion, {
            ...workflow,
            Schema: updatedSchema,
          });
        }
      }
    };

  const toggleStepOutputs =
    (activeStep: ProtocolStep, elementInstanceId: string) =>
    async (param: Parameter, checked: boolean) => {
      const stepIndex = steps.findIndex(step => step.id === activeStep.id);
      const id = uuid();
      const displayName = param.configuration?.displayName || param.name;
      const updatedOutputs = checked
        ? [
            ...activeStep.outputs,
            {
              id,
              displayName,
              elementInstanceId,
            },
          ]
        : activeStep.outputs.filter(output => output.displayName !== displayName);
      const newStep = { ...activeStep, outputs: updatedOutputs };
      const newSteps = [
        ...steps.slice(0, stepIndex),
        newStep,
        ...steps.slice(stepIndex + 1),
      ];
      await handleUpdateProtocol(editVersion, {
        protocol: { ...protocol, steps: newSteps },
      });

      if (workflowId) {
        if (checked) {
          const newSchemaOutput = {
            id,
            path: newElementPath(elementInstanceId, param.name),
            typeName: param.type,
          };
          const updatedSchemaOutputs = [...(schema?.outputs || []), newSchemaOutput];
          const updatedSchema = { ...workflow.Schema, outputs: updatedSchemaOutputs };
          await handleUpdateWorkflow(workflowId, workflowVersion, {
            ...workflow,
            Schema: updatedSchema,
          });
        } else {
          const updatedSchemaOutputs = schema?.outputs?.filter(output => {
            const path = output.path;
            return !(path.includes(param.name) && path.includes(elementInstanceId));
          });
          const updatedSchema = { ...workflow.Schema, outputs: updatedSchemaOutputs };
          await handleUpdateWorkflow(workflowId, workflowVersion, {
            ...workflow,
            Schema: updatedSchema,
          });
        }
      }
    };

  const deleteStepInput = (activeStep: ProtocolStep) => async (index: number) => {
    const stepIndex = steps.findIndex(step => step.id === activeStep.id);
    const inputToRemove = activeStep.inputs[index];
    const updatedInputs = activeStep.inputs.filter((_, idx) => idx !== index);
    const newStep = { ...activeStep, inputs: updatedInputs };
    const newSteps = [
      ...steps.slice(0, stepIndex),
      newStep,
      ...steps.slice(stepIndex + 1),
    ];
    await handleUpdateProtocol(editVersion, {
      protocol: { ...protocol, steps: newSteps },
    });
    if (workflowId) {
      const updatedSchemaInputs = schema?.inputs?.filter(
        input => input.id !== inputToRemove.id,
      );
      const updatedSchema = { ...workflow.Schema, inputs: updatedSchemaInputs };
      await handleUpdateWorkflow(workflowId, workflowVersion, {
        ...workflow,
        Schema: updatedSchema,
      });
    }
  };
  const deleteStepOutput = (activeStep: ProtocolStep) => async (index: number) => {
    const stepIndex = steps.findIndex(step => step.id === activeStep.id);
    const outputToRemove = activeStep.outputs[index];
    const updatedOutputs = activeStep.outputs.filter((_, idx) => idx !== index);
    const newStep = { ...activeStep, outputs: updatedOutputs };
    const newSteps = [
      ...steps.slice(0, stepIndex),
      newStep,
      ...steps.slice(stepIndex + 1),
    ];
    await handleUpdateProtocol(editVersion, {
      protocol: { ...protocol, steps: newSteps },
    });
    if (workflowId) {
      const updatedSchemaOutputs = schema?.outputs?.filter(
        input => input.id !== outputToRemove.id,
      );
      const updatedSchema = { ...workflow.Schema, inputs: updatedSchemaOutputs };
      await handleUpdateWorkflow(workflowId, workflowVersion, {
        ...workflow,
        Schema: updatedSchema,
      });
    }
  };

  const deleteStep = async (stepId: string) => {
    const steps = protocol.steps.filter(step => step.id !== stepId);
    await handleUpdateProtocol(editVersion, { protocol: { ...protocol, steps } });
  };

  const changeStep = async (newStep: ProtocolStep) => {
    const steps = protocol.steps;
    const stepIndex = steps.findIndex(step => step.id === newStep.id);
    const newSteps = [
      ...steps.slice(0, stepIndex),
      newStep,
      ...steps.slice(stepIndex + 1),
    ];
    await handleUpdateProtocol(editVersion, {
      protocol: { ...protocol, steps: newSteps },
    });
  };

  const getElementInstance = (instanceId: string) => {
    if (deserialisedElementInstances) {
      const ei = deserialisedElementInstances.find(ei => ei.Id === instanceId);
      return ei;
    }
    return undefined;
  };
  const update = partial(handleUpdateProtocol, editVersion);

  const state = {
    workflowId,
    protocol,
    schema,
    steps,
    toggleStepInputs,
    deleteStepInput,
    toggleStepOutputs,
    deleteStepOutput,
    addNewStep,
    changeStep,
    deleteStep,
    getElementInstance,
    name,
    shortDescription,
    update,
    exampleSimulation,
  };

  return <ProtocolContext.Provider value={state}>{children}</ProtocolContext.Provider>;
};
