import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import ProtocolElementInstancePanel from 'client/app/apps/protocols/ProtocolElementInstancePanel/ProtocolElementInstancePanel';
import StepCard from 'client/app/apps/protocols/StepCard/StepCard';
import { useProtocolStep } from 'client/app/apps/protocols/useProtocolStep';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { ElementInstance } from 'common/types/bundle';
import Button from 'common/ui/components/Button';

export const DefineProtocol = () => {
  const [selectedObjectIds, setSelectedObjects] = useState<string[]>([]);
  const {
    addNewStep,
    toggleStepInputs,
    deleteStepInput,
    toggleStepOutputs,
    deleteStepOutput,
    steps,
    workflowId,
    protocol,
    schema,
    getElementInstance,
  } = useProtocolContext();
  const { tabId, activeStep, setTabId, getStepProps } = useProtocolStep();
  const [elementInstance, setElementInstance] = useState<ElementInstance>();

  // TODO: Replace with the final implementation once the ProtocolElementInstancePanel is merged.
  // This is temporary code to demonstrate that retrieving the relevant data is possible.
  useEffect(() => {
    if (selectedObjectIds.length === 1) {
      const id = selectedObjectIds[0];
      const ei = getElementInstance(id);
      setElementInstance(ei);
    } else {
      setElementInstance(undefined);
    }
  }, [getElementInstance, selectedObjectIds]);

  return (
    <Content>
      <SidePanel>
        <Typography variant="h2">Define Protocol</Typography>
        {steps.map(step => (
          <StepCard
            key={step.id}
            tabId={tabId}
            setTabId={setTabId}
            {...getStepProps(step)}
            // TODO: I left those out for now
            // Not sure how to best address those. Will update once we get to it.
            onDeleteInput={activeStep && deleteStepInput(activeStep)}
            onDeleteOutput={activeStep && deleteStepOutput(activeStep)}
          />
        ))}
        <Button variant="primary" color="primary" onClick={addNewStep}>
          + Add a step
        </Button>
      </SidePanel>
      <PreviewWrapper>
        {workflowId && (
          <WorkflowPreview
            workflowId={workflowId}
            setSelectedObjects={setSelectedObjects}
            selectedObjectIds={selectedObjectIds}
          />
        )}
      </PreviewWrapper>
      {activeStep && elementInstance && (
        <InstancePanelWrapper>
          <ProtocolElementInstancePanel
            activeStep={activeStep}
            protocol={protocol}
            schema={schema}
            elementInstance={elementInstance}
            activeTab={tabId}
            onInputsChange={toggleStepInputs(activeStep, elementInstance.Id)}
            onOutputsChange={toggleStepOutputs(activeStep, elementInstance.Id)}
            onClose={() => setSelectedObjects([])}
          />
        </InstancePanelWrapper>
      )}
    </Content>
  );
};

const Content = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  minWidth: '350px',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(6, 4),
}));

const PreviewWrapper = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}));

const InstancePanelWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 10,
  margin: '8px 8px 8px 8px',
  height: 'calc(100vh - 122px)',
  overflow: 'hidden',
}));
